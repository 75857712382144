import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { URL_GEOLOCALIZATION } from '../../../config';
import axios from "axios";
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";
// import {IconButton} from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import './fileMobile.css';
import { toast, ToastContainer } from 'react-toastify';
import {searchStores} from "../../../redux/stores";
import SelectInput from "../../../components/select";
import {useHistory} from "react-router-dom";
import { createAssistControl, clear_assist_control, CREATE_ASSIST_CONTROL, searchAssistControl } from '../../../redux/assist_control';
import Footer from '../../../components/footer';
import { SocketContext } from '../../../config/ws-client';

function inittDate() {
    try {
        let date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var value = year + '-' + month + '-' + day;
        return value;
    } catch (e) {
        return null;
    }
}

const CheckIn = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const {socket} = useContext(SocketContext);
    const {token, user} = useSelector((state) => state.users_module.login.data) || null;

    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [date, setDate] = useState(inittDate());
    const [adress, setAdrees] = useState('');
    const [time, setTime] = useState('08:00:00');
    // const [namePhoto, setNamePhoto] = useState('');
    // const [uriPhoto, setUriPhoto] = useState('');
    const [type, setType] = useState('tienda');
    const [marking, setMarking] = useState(false);
    // const [file, setFile] = useState(null);
    // const [fileImage, setFileImage] = useState(null);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    // const [modalCamera, setModalCamera] = useState(false);
    const [modalMessage, setModalMessage] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [stores, setStores] = useState([]);
    const [id_store, setIdStore] = useState(0);
    const [store, setStore] = useState('');
    const [isStore, setIsStore] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgApi, setMsgApi] = useState('');

    useEffect(() => {
        assist_control();
        // eslint-disable-next-line
      }, []);

    // function handleClear() {
    //     setFileImage(null);
    //     setFile(null);
    // }

    // const web = navigator.userAgent.toUpperCase();
    // let mobile = web.indexOf("MOBILE");
    // let android = web.indexOf("ANDROID");
    // let iphone = web.indexOf("IPHONE");

    socket.onmessage = ((message) => {
        const dataFromServer = JSON.parse(message.data);
        if (dataFromServer) {
            if (dataFromServer.type_message === 'ingreso') {
                setMsgApi(dataFromServer.message);
            }
        }
    })


    function getGeolocation() {
        // setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                geodecoder(position.coords.latitude, position.coords.longitude)
            },
            (err) => {console.log(err)},
            {enableHighAccuracy: false, timeout: 27000, maximumAge: 30000});
    }

    function geodecoder(latitude, longitude) {
        var config = {
          method: 'get',
          url:
            URL_GEOLOCALIZATION +
            '?format=jsonv2&lat=' +
            latitude +
            '&lon=' +
            longitude,
          headers: {},
        };
        axios(config)
          .then(function (response) {
              setAdrees(response.data.display_name);
          })
          .catch(function (error) {
            console.log(error);
        });
    }

    function clear_store() {
        setTime('07:00:00');
        setAdrees('');
        // setNamePhoto('');
        // setUriPhoto('');
        setLatitude('');
        setLongitude('');
        setIdStore(0);
        setType('tienda');
        setStores([]);
        setStore([]);
        setMarking(false);
      }

    async function assist_control() {
        // let params_initial = "?usuario=" + user + '&&fecha=' + date + '&&codigo_tipo=jornada';
        // const response_initial = await searchAssistControl(token, params_initial);
        // if (response_initial.data.cantidad === 0) {
        //     setMsg('Para continuar debe registrar la marcación de la jornada');
        //     setModalMessage(true);
        // } else if (response_initial.data.datos[0].ubicacion_final) {
        //     setMsg('No puede ingresar al registro después de haber terminado su jornada laboral');
        //     setModalMessage(true);
        // } else {

        let params = "?usuario=" + user + '&&fecha=' + date + '&&codigo_tipo=' + type;
        const response = await searchAssistControl(token, params);
        let cant = parseInt(response.data.cantidad);
        if (cant > 0) {
            cant = cant - 1;
        } else {
            cant = 0;
        }
        getStores(user, token, false);

        if (
            response.data.cantidad === 0 ||
            response.data.datos[cant].ubicacion_final !== ''
        ) {
            clear_store();
        }
        else {
            let data = response.data.datos[cant];
            setIdStore(data.id_tienda);
            setStore(data.tienda);
            setTime(data.ubicacion_inicial.hora);
            let latitude = data.ubicacion_inicial.latitud;
            let longitude = data.ubicacion_inicial.longitud;
            setLatitude(latitude);
            setLongitude(longitude);
            setMsgApi(data.mensaje_ingreso);
            geodecoder(latitude, longitude);
            // let nameImage = URLAPI + data.ubicacion_inicial.imagenes[0].nombre;
            // let image = URLAPI + data.ubicacion_inicial.imagenes[0].imagen;
            // setNamePhoto(nameImage);
            // setUriPhoto(image);
            setMarking(true);
        }
        // }
    }

    async function getStores(user, token, value) {
        setIsLoading(true);
        const params = value === true ? '' : '?usuario=' + user
        const response = await searchStores(token, params);
        console.log('tiendas')
        console.log(response)
        if (response.data && response.data.datos) {
            setStores(response.data.datos);
        }
        setIsLoading(false);
    }

    // const handleSubmit = async () => {
    //     setNamePhoto(file.name);
    //     setUriPhoto(fileImage);
    //     // setModalCamera(false);
    // }

    // const handleCapture = (target) => {
    //     if (target.files) {
    //         if (target.files.length !== 0) {
    //             const file = target.files[0];
    //             setFile(file);
    //             const newUrl = URL.createObjectURL(file);
    //             setFileImage(newUrl);
    //         }
    //     }
    // };

    const handleChangeStore = (e) => {
        setIdStore(parseInt(e.target.value));
    };

    function changeIsStore(value) {
        getStores(user, token, value);
        setIsStore(value);
    }

    const _create_assist_control = useSelector(
        (state) => state.assist_control_module._create_assist_control
    );

    useEffect(() => {
        if (_create_assist_control) {
            if (_create_assist_control.status === 201) {
                assist_control();
                dispatch(clear_assist_control(CREATE_ASSIST_CONTROL));
                setMarking(true);
                setModalConfirm(false);
                //assist_control();
            } else if (_create_assist_control.status !== 0) {
                setModalConfirm(false);
                toast.error(_create_assist_control.message, {position: toast.POSITION.TOP_RIGHT});
                dispatch(clear_assist_control(CREATE_ASSIST_CONTROL));
                
            }
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [_create_assist_control, dispatch]);

    // const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
    //     return new Promise((resolve, reject) => {
    //         const $canvas = document.createElement("canvas");
    //         const imagen = new Image();
    //         imagen.onload = () => {
    //             $canvas.width = imagen.width;
    //             $canvas.height = imagen.height;
    //             $canvas.getContext("2d").drawImage(imagen, 0, 0);
    //             $canvas.toBlob(
    //                 (blob) => {
    //                     if (blob === null) {
    //                         return reject(blob);
    //                     } else {
    //                         resolve(blob);
    //                     }
    //                 },
    //                 "image/jpeg",
    //                 porcentajeCalidad / 100,
    //             );
    //         };
    //         imagen.src = URL.createObjectURL(imagenComoArchivo);
    //     });
    // };

    async function create_marking() {
        setIsLoading(true);
        // const blob = await comprimirImagen(file, 40);
        // var fileConvert = new File([blob], file.name);
        if (id_store === 0) {
            toast.error("Debe seleccionar una tienda", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let data = {};
        data['usuario'] = user;
        data['id_tienda'] = id_store;
        data['codigo_tipo'] = type;
        data['latitud'] = latitude;
        data['longitud'] = longitude;
        // data['imagen'] = fileConvert;
        dispatch(createAssistControl(token, data));

    }

    return (
        <>
            <div className="app-content content">
                {isLoading && <LoadingSpinner/>}
                <ToastContainer/>
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    {(msgApi !== '') &&
                                        <div className='mb-1'>
                                            <div className='text-right'>
                                                <span className='texto_24'  style={{background: '#FFC912', padding: '5px 20px'}}>{msgApi}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className='text-center'>
                                        <p className='texto_24'>Entrada a</p>
                                        <p className='texto_30'>Tienda</p>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <div className='mb-1'>
                                                <img className='ml-2 img_1'
                                                    src={window.location.origin + "/base/app-assets/images/icon/entrada.png"}
                                                    alt="img"
                                                />
                                                <span className="ml-1 texto_24">Entrada</span>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-1">
                                            <div className="form-group ml-2">
                                                <label>Tienda</label>
                                                <SelectInput
                                                    label="tienda"
                                                    id="id_store"
                                                    name="id_store"
                                                    value={id_store}
                                                    select={store}
                                                    onChange={handleChangeStore}
                                                    options={stores ? stores.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    })) : []}
                                                    disabled={marking}
                                                />
                                            </div>
                                        </div>
                                        {!marking && (
                                            <div className="col-12 mb-2">
                                                <div className="form-check ml-2">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input"
                                                                onClick={() => {
                                                                    changeIsStore(!isStore);
                                                                }}  checked={isStore}
                                                                style={{width: 15, height: 15, marginTop: -1}}/>
                                                        <span className="ml-1">Tarea en reemplazo</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        <div className='col-12 text-center mb-1'>
                                            <span className='border_clock texto_48'>{time}</span>
                                        </div>
                                        <div className='col-12 mt-1'>
                                            {/*{namePhoto ? (*/}
                                            {/*    <img className='ml-2 img_2'*/}
                                            {/*        src={uriPhoto}*/}
                                            {/*        alt="img"*/}
                                            {/*    />*/}
                                            {/*) : (*/}
                                            {/*    <img className='ml-2 img_2'*/}
                                            {/*        src={window.location.origin + "/base/app-assets/images/icon/image.png"}*/}
                                            {/*        alt="img"*/}
                                            {/*    />*/}
                                            {/*)}*/}
                                            <img className='ml-2'
                                                src={window.location.origin + "/base/app-assets/images/icon/position.png"}
                                                alt="img"
                                                height="30"
                                                width="30"/>
                                            <span className="ml-1 texto_16">{adress}</span>
                                        </div>
                                        <div className='col-12 text-center mt-2'>
                                            {!marking && (
                                                (adress) ?
                                                    <>
                                                        {/*eslint-disable-next-line*/}
                                                        <a href="#" onClick={() => {
                                                            setModalConfirm(true);
                                                            //create_marking();
                                                        }} className="btn text-bold-700 btn_2">
                                                            Confirmar
                                                        </a>
                                                    </>
                                                    : 
                                                    <>
                                                        {/*eslint-disable-next-line*/}
                                                        <a href="#" onClick={() => {
                                                            setType('tienda');
                                                            getGeolocation();
                                                            // handleClear();
                                                            // setModalCamera(true);
                                                        }} className="btn text-bold-700 btn_1">
                                                            Marcar
                                                        </a>
                                                    </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Modal className="modal-main" show={modalCamera} style={{width: "80%"}}>*/}
                {/*    {isLoading && <LoadingSpinner/>}*/}
                {/*    <div className="modal-header">*/}
                {/*        <p style={{color: 'transparent'}}>.</p>*/}
                {/*        <button type="button" style={{marginRight: -24}}*/}
                {/*                className="btn btn-calendar badge-circle" onClick={() => {*/}
                {/*            handleClear();*/}
                {/*            setModalCamera(false);*/}
                {/*            setUriPhoto('');*/}
                {/*            setNamePhoto('');*/}
                {/*        }}> X*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    {(mobile === -1 && android === -1 && iphone === -1) ? (*/}
                {/*        <div className="container text-center">*/}
                {/*            <h5 className='text-bold-400 texto_24'>Use un teléfono para continuar</h5>*/}
                {/*        </div> */}
                {/*    ) : (*/}
                {/*        <div className="root">*/}
                {/*            <Grid container>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <div className={!file && 'btnPhoto'}>*/}
                {/*                        <h5 className='text-bold-400 texto_24 mt-1'>Presione el botón para tomar la fotografía</h5>*/}
                {/*                        {file && (*/}
                {/*                            <>*/}
                {/*                                <Box*/}
                {/*                                    display="flex"*/}
                {/*                                    justifyContent="center"*/}
                {/*                                    border={1}*/}
                {/*                                    className="imgBox"*/}
                {/*                                >*/}
                {/*                                    <img src={fileImage} alt={"snap"} className="img" width="80%" height="80%"/>*/}
                {/*                                </Box>*/}
                {/*                            </>*/}
                {/*                        )}*/}
                {/*                        <input*/}
                {/*                            accept="image/*"*/}
                {/*                            className="input"*/}
                {/*                            id="icon-button-file"*/}
                {/*                            type="file"*/}
                {/*                            capture="environment"*/}
                {/*                            onChange={(e) => handleCapture(e.target)}*/}
                {/*                        />*/}
                {/*                        <label htmlFor="icon-button-file">*/}
                {/*                            <IconButton*/}
                {/*                                color="primary"*/}
                {/*                                aria-label="Tomar fotografía"*/}
                {/*                                component="span"*/}
                {/*                            >*/}
                {/*                                <PhotoCameraRoundedIcon fontSize="large" color="primary"/>*/}
                {/*                            </IconButton>*/}
                {/*                        </label>*/}
                {/*                        {*/}
                {/*                            fileImage && (*/}
                {/*                                <div className="text-center mb-1">*/}
                {/*                                    /!*eslint-disable-next-line *!/*/}
                {/*                                    <a href="#" onClick={handleSubmit} className="btn text-bold-700 mt-1 btn_1">*/}
                {/*                                        Continuar*/}
                {/*                                    </a>*/}
                {/*                                </div>*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    </div>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</Modal>*/}
                <Modal className="modal-main" show={modalMessage} style={{width: "70%"}}>
                    <div className="m-1 text-center">
                        <img 
                            src={window.location.origin + "/base/app-assets/images/icon/help.png"}
                            alt="img"
                            className='img_3'
                        />
                        <br/><br/>
                        <p className='texto_24 text-bold-400'>{msg}</p>
                        <br/><br/>
                        {/*eslint-disable-next-line*/}
                        <a href="#" onClick={() => {
                            setModalMessage(false);
                            history.push("/");
                        }} className="btn text-bold-700 btn_1">
                            Ok
                        </a>
                    </div>
                </Modal>
                <Modal className="modal-main" show={modalConfirm} style={{width: "70%"}}>
                    <div className="m-1 text-center">
                        <img 
                            src={window.location.origin + "/base/app-assets/images/icon/help.png"}
                            alt="img"
                            className='img_3'
                        />
                        <br/><br/>
                        <p className='texto_24 text-bold-400'>¿Desea confirmar el registro?</p>
                        <br/><br/>
                        {/*eslint-disable-next-line*/}
                        <a href="#" onClick={() => {
                            create_marking();
                        }} className="mb-1 btn text-bold-700 btn_1">
                            Si
                        </a>
                        {/*eslint-disable-next-line*/}
                        <a href="#" onClick={() => {
                            setModalConfirm(false);
                        }} className="btn text-bold-700 btn_3">
                            No
                        </a>
                    </div>
                </Modal>
            </div>
            <div className="app-content content">
                <Footer/>
            </div>
        </>
    )
}

export default CheckIn;