import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";

const Init = () => {
    let history = useHistory();

    const _session_data = useSelector((state) => state.users_module._session_data);

    const [user_data, setUserData] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        foto: "",
        grupo_permiso: {
            nombre: ""
        }
    });

    useEffect(() => {
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body mt-1">
                                <div className='text-center'>
                                    <p className='texto_24'>Hola</p>
                                    <p className='texto_30'>{user_data.nombres} {user_data.apellidos}</p>
                                </div>
                                <div className='row'>
                                    {/* <div className="col-sm-6 col-12">
                                        <div className='div_card mt-1 pb-2 text-center' onClick={() => {history.push("/marcacion");}}>
                                            <img className='mt-2 img_3'
                                                src={window.location.origin + "/base/app-assets/images/icon/marcacion.png"}
                                                alt="img"
                                            />
                                            <h5 style={{fontSize: 16, color: '#000000', fontWeight: 700}}>Marcación</h5>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 col-12">
                                        <div className='div_card mt-1 pb-2 text-center' onClick={() => {history.push("/ingreso-tienda");}}>
                                            <img className='mt-2 img_3'
                                                src={window.location.origin + "/base/app-assets/images/icon/ingresar.png"}
                                                alt="img"
                                            />
                                            <h5 style={{fontSize: 16, color: '#000000', fontWeight: 700}}>Entrada</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12">
                                    <div className='div_card mt-1 pb-2 text-center' onClick={() => {history.push("/salida-tienda");}}>
                                            <img className='mt-2 img_3'
                                                src={window.location.origin + "/base/app-assets/images/icon/salir.png"}
                                                alt="img"
                                            />
                                            <h5 style={{fontSize: 16, color: '#000000', fontWeight: 700}}>Salida</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12">
                                        <div className='div_card mt-1 pb-2 text-center' onClick={() => {history.push("/tareas");}}>
                                            <img className='mt-2 img_3'
                                                src={window.location.origin + "/base/app-assets/images/icon/agenda.png"}
                                                alt="img"
                                            />
                                            <h5 style={{fontSize: 16, color: '#000000', fontWeight: 700}}>Agenda</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Init;