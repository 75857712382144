import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumbs";
import CardItem from "../../../components/card-item";
import Modal from "../../../components/modal";
import Calendar from 'react-calendar';
import {
    searchTaskScheduledUser,
    createTaskScheduled,
    updateTaskScheduled,
    //clear_task_scheduled,
    //CREATE_TASK_SCHEDULED,
    //UPDATE_TASK_SCHEDULED,
    //RESCHEDULED_TASK_SCHEDULED,
    //CONFIRM_TASK_SCHEDULED,
    rescheduledTaskScheduled,
    confirmTaskScheduled,
    confirmSkipTaskScheduled,
    //CREATE_LOCATION_TASK_SCHEDULED,
    createLocationTaskScheduled,
    //CANCEL_TASK_SCHEDULED,
    cancelTaskScheduled,
    getLogTaskScheduled,
    createLogTaskScheduled
} from "../../../redux/task_scheduled";
import SelectInput from "../../../components/select";
import {searchTaskList, searchTask} from "../../../redux/task";
import TextArea from "../../../components/text-area";
import {useHistory} from "react-router-dom";
import {searchStores} from "../../../redux/stores";
import Input from "../../../components/input";
import {URLAPI} from "../../../config";
import Footer from "../../../components/footer";
import LoadingSpinner from "../../../components/loading-spinner";
import {ReactComponent as ReactCancel} from './cancel.svg';
import Select from "react-select";
//import { searchAssistControl } from "../../../redux/assist_control";

function convertDate(date) {
    try {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var value = year + '-' + month + '-' + day;
        return value;
    } catch (e) {
        return null;
    }
}

/* function inittDate() {
    try {
        let date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        var value = year + '-' + month + '-' + day;
        return value;
    } catch (e) {
        return null;
    }
}
 */
const TaskScheduledUser = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const {token, user} = useSelector((state) => state.users_module.login.data) || null;

    const task_scheduled = useSelector(
        (state) => state.task_scheduled_module._get_all_task_scheduled_user
    );

    const _get_user_data = useSelector(
        (state) => state.users_module._session_data
    );

    const _get_all_observation = useSelector(
        (state) => state.observation_module._get_all_observation
    );
    
    const _get_all_skip_task = useSelector(
        (state) => state.skip_task_module._get_all_skip_task
    );

    const [data, setData] = useState([]);
    const [item, setItem] = useState({});
    const [tipoTarea, setTipoTarea] = useState('');
    const [modalCreate, setModalCreate] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditDate, setModalEditDate] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalSkip, setModalSkip] = useState(false);
    const [notification, setNotification] = useState(0);
    const [taskList, setTaskList] = useState([]);
    const [observations, setObservations] = useState([]);
    const [skips, setSkips] = useState([]);
    const [id_observation, setIdObservation] = useState(0);
    const [observation, setObservation] = useState('');
    const [task, setTask] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [task_select, setTaskSelect] = useState([]);
    const [stores, setStores] = useState([]);
    //const [files, setFiles] = useState([]);
    const [resources, setResources] = useState([]);
    const [images, setImages] = useState([]);
    const [filesUpdate, setFilesUpdate] = useState([]);
    const [id_tasklist, setIdTaskList] = useState(0);
    const [id_task, setIdTask] = useState(0);
    const [id_store, setIdStore] = useState(0);
    const [id_skip_task, setIdSkipTask] = useState(0);
    const [observationInitial, setObservationInitial] = useState('');
    const [motivo, setMotivo] = useState('');
    const [observationEnd, setObservationEnd] = useState('');
    const [date, setDate] = useState([new Date()]);
    const [dateCalendar, setDateCalendar] = useState([new Date()]);
    const [date_re, setDateRe] = useState('');
    const [permisos, setPermisos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    //const [msg, setMsg] = useState('');
    //const [modalMessage, setModalMessage] = useState(false);


    useEffect(() => {
        /* assist_control();    */
        setIsLoading(false);
        setData(task_scheduled.data.datos);
        setDateCalendar(task_scheduled.data.values_date)
        //getTaskList(tipoTarea, token);
        getTask(user, token);
        getStores(user, token, false);
        // eslint-disable-next-line
    }, [task_scheduled, token, user, tipoTarea]);

    useEffect(() => {
        if (_get_user_data.data) {
            setTipoTarea(_get_user_data.data.tipo_tarea);
        }
        setIsLoading(false);
    }, [_get_user_data, token, user]);

    useEffect(() => {
        console.log(_get_all_observation.data)
        if (_get_all_observation && _get_all_observation.data && _get_all_observation.data.datos) {
            setObservations(_get_all_observation.data.datos);
        }
    }, [_get_all_observation]);


    useEffect(() => {
        if (_get_all_skip_task && _get_all_skip_task.data && _get_all_skip_task.data.datos) {
            setSkips(_get_all_skip_task.data.datos);
        }
    }, [_get_all_skip_task]);

    /* async function assist_control() {
        const value_date = inittDate();
        let params = "?usuario=" + user + '&&fecha=' + value_date + '&&codigo_tipo=jornada';
        const response = await searchAssistControl(token, params);
        if (response.data.datos.length === 0) {
            setMsg('Para continuar debe registrar la marcación de la jornada');
            setModalMessage(true);
        } else {
            if (task_scheduled.data.datos) {
                setData(task_scheduled.data.datos);
                setDateCalendar(task_scheduled.data.values_date)
                getTaskList(tipoTarea, token);
                getStores(user, token, false);
            }
        }
    } */

    async function getTaskList(type_task, token) {
        setIsLoading(true);
        const params = '?tipo_tarea=' + type_task
        const response = await searchTaskList(token, params);
        setTaskList(response.data.datos);
        setIsLoading(false);
    }

    async function getTask(user, token) {
        setIsLoading(true);
        const params = '?usuario=' + user;
        const response = await searchTask(token, params);
        let options = [];
        // eslint-disable-next-line
        response.data.datos.map((item) => {
            const grupo = _get_user_data.data.grupo_permiso.nombre;
            let result = item.roles.filter(x => x.nombre === grupo)
            if (result.length > 0){
                options.push({
                    value: item.id,
                    label: item.nombre,
                });
            }
        });
        setTasks(options);
        setIsLoading(false);
    }

    async function getStores(user, token, value) {
        setIsLoading(true);
        const params = value === true ? '' : '?usuario=' + user
        const response = await searchStores(token, params);
        setStores(response.data.datos);
        setIsLoading(false);
    }

    async function getTaskScheduled(date) {
        const value_date = convertDate(date);
        const params = '?usuario=' + user + '&&fecha=' + value_date
        const response = await searchTaskScheduledUser(token, params);
        setData(response.data.datos)
    }

    // function geodecoder(latitude, longitude) {
    //     console.log(latitude, longitude)
    //     var config = {
    //         method: 'get',
    //         url:
    //             'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' +
    //             latitude +
    //             '&lon=' +
    //             longitude,
    //         headers: {},
    //     };
    //     axios(config)
    //         .then(function (response) {
    //             console.log(response.data.display_name);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }

    function getGeolocation() {
        // setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            function (position) {
                dispatch(createLocationTaskScheduled(token,
                    {
                        latitud: position.coords.latitude,
                        longitud: position.coords.longitude,
                        usuario: user,
                        id_tarea_horario: item.id
                    }
                ));
            },
            (err) => {console.log(err)},
            {enableHighAccuracy: false, timeout: 27000, maximumAge: 30000});
    }

    const _create_location_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._create_location_task_scheduled
    );

    useEffect(() => {
        if (_create_location_task_scheduled.status === 201) {
            console.log('Ubicación creada correctamente')
            // toast.success('Ubicación creada correctamente', {position: toast.POSITION.TOP_RIGHT});
        } else if (_create_location_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(CREATE_LOCATION_TASK_SCHEDULED));
            toast.error(_create_location_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_create_location_task_scheduled, dispatch]);

    const onChangeCalendar = async (value) => {
        setDate([value]);
        getTaskScheduled(value);
    }

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    const handleChangeTaskList = (e) => {
        for (var value of taskList) {
            if (value.id === parseInt(e.target.value)) {
                setIdTaskList(parseInt(e.target.value));
                setTask(value.tareas)
                break;
            }
        }
        document.getElementById("id_task").selectedIndex = 0;
    };

    const handleChangeTask = (e) => {
        setIdTask(parseInt(e.target.value));
    };

    const handleChangeStore = (e) => {
        setIdStore(parseInt(e.target.value));
    };

    const handleChangeObservationInitial = (e) => {
        setObservationInitial(e.target.value);
    };

    const handleChangeReason = (e) => {
        setMotivo(e.target.value);
    };

    const handleChangeObservationEnd = (e) => {
        setObservationEnd(e.target.value);
    };

    const handleChangeDate = (e) => {
        setDateRe(e.target.value);
    };

    /* const handleFileChange = (e) => {
        setFiles([
            ...files,
            e.target.files[0]
        ]);
    };
 */
    const handleFileChangeResource = (e, id) => {
        let files = [];
        for (var ft of resources) {
            if (parseInt(ft.id) === parseInt(id)) {
                files.push({
                    'id': ft.id,
                    'nombre': e.target.files[0].name,
                    'archivo': e.target.files[0],
                    'tipo': e.target.files[0].type,
                    'nombre_template': ft.nombre_template,
                    'archivo_template': ft.archivo_template,
                });
            } else {
                files.push({
                    'id': ft.id,
                    'nombre': ft.nombre,
                    'archivo': ft.archivo,
                    'tipo': ft.tipo,
                    'nombre_template': ft.nombre_template,
                    'archivo_template': ft.archivo_template,
                });
            }
        }
        setResources(files);
        /* setResources([
            ...resources,
            e.target.files[0]
        ]); */
        getGeolocation(); 
    };

    const handleFileChangeImage = (e) => {
        setImages([
            ...images,
            e.target.files[0]
        ]);
        getGeolocation();
    };

    // const _create_task_scheduled = useSelector(
    //     (state) => state.task_scheduled_module._create_task_scheduled
    // );

    /* useEffect(() => {
        if (_create_task_scheduled.status === 201) {
            // toast.success('Creado correctamente', {position: toast.POSITION.TOP_RIGHT});
            getTaskScheduled(date[0]);
            setModalCreate(false);
            setIsStore(false);
            getStores(user, token, false);
            // history.push("/tareas");
        } else if (_create_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(CREATE_TASK_SCHEDULED));
            toast.error(_create_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_create_task_scheduled, dispatch, history, token, date, user]); */

    const _update_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._update_task_scheduled
    );

    useEffect(() => {
        if (_update_task_scheduled.status === 200) {
            // toast.success('Creado correctamente', {position: toast.POSITION.TOP_RIGHT});
            getTaskScheduled(date[0]);
            setModalEdit(false);
            setModalDetail(false);
            // history.push("/tareas");
        } else if (_update_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(UPDATE_TASK_SCHEDULED));
            toast.error(_update_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_update_task_scheduled, dispatch, history, token, date, user]);

    function save_task_scheduled() {
        setIsLoading(true);
        /* if (id_tasklist === 0) {
            toast.error("Debe seleccionar una lista de tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id_task === 0) {
            toast.error("Debe seleccionar una tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } */
        
        if (task_select.length === 0) {
            toast.error("Debe seleccionar una tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id_store === 0) {
            toast.error("Debe seleccionar una tienda", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        task_select.forEach((x) => {
            const data = new FormData();
            let replacement_task = '0'
            if (isStore) {
                replacement_task = '1'
            }
            data.append('tarea_reemplazo', replacement_task);
            data.append('id_tarea', x.value);
            data.append('id_tienda', id_store);
            data.append('usuario', user);
            data.append('fecha', convertDate(date[0]));
            data.append('observacion_inicial', observationInitial);
            dispatch(createTaskScheduled(token, data));
        });
        getTaskScheduled(date[0]);
        setModalCreate(false);
        setIsStore(false);
        getStores(user, token, false);
    }


    function edit_task_scheduled() {
        setIsLoading(true);
        if (id_tasklist === 0) {
            toast.error("Debe seleccionar una lista de tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id_task === 0) {
            toast.error("Debe seleccionar una tarea", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id_store === 0) {
            toast.error("Debe seleccionar una tienda", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        const data = new FormData();
        /* files.forEach((file, i) => {
            data.append(`archivos`, file, file.name);
        }); */
        data.append('id_tarea', id_task);
        data.append('id_tienda', id_store);
        data.append('usuario', user);
        data.append('observacion_inicial', observationInitial);
        dispatch(updateTaskScheduled(token, item.id, data));
    }


    const _rescheduled_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._rescheduled_task_scheduled
    );

    useEffect(() => {
        if (_rescheduled_task_scheduled.status === 200) {
            getTaskScheduled(date[0]);
            setModalEditDate(false);
            setModalEdit(false);
            setModalDetail(false);
        } else if (_rescheduled_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(RESCHEDULED_TASK_SCHEDULED));
            toast.error(_rescheduled_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_rescheduled_task_scheduled, dispatch]);

    function updateDate() {
        setIsLoading(true);
        if (date_re === '') {
            toast.error("Debe seleccionar una fecha", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (motivo === '') {
            toast.error("Debe ingresar un motivo", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        dispatch(rescheduledTaskScheduled(token, item.id, {fecha: date_re, motivo: motivo}));
    }

    const _confirm_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._confirm_task_scheduled
    );

    useEffect(() => {
        if (_confirm_task_scheduled.status === 200) {
            if (isConfirm === true) {
                getGeolocation();
                create_log('Terminar tarea');
            }
            getTaskScheduled(date[0]);
            setModalConfirm(false);
            setModalDetail(false);
            setNotification(false);
        } else if (_confirm_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(CONFIRM_TASK_SCHEDULED));
            toast.error(_confirm_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_confirm_task_scheduled, dispatch]);

    function _confirmTaskScheduled() {
        setIsLoading(true);
        for (var f of resources) {
            if (!f.archivo) {
              toast.error("Falta agregar resultado(s)", {position: toast.POSITION.TOP_RIGHT});
              setIsLoading(false);
              return;
            }
        }

        if (!id_observation === 0) {
            toast.error("Debe seleccionar una observación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        if (!observationEnd) {
            toast.error("Debe ingresar una observación final", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        /* if (resources.length === 0) {
            toast.error("Debe cargar al menos un resultado", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } */
        /* if (images.length === 0) {
            toast.error("Debe cargar al menos un imagen", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        } */

        const data = new FormData();
        resources.forEach((file, i) => {
            if (file.tipo) {
                data.append(`resultados`, file.archivo, file.nombre + ',' + file.id.toString());
            }
        });
        images.forEach((file, i) => {
            data.append(`imagenes`, file, file.name);
        });
        data.append('id_observacion', id_observation);
        data.append('observacion_final', observationEnd);
        let not = '0'
        if (notification) {
            not = '1'
        }
        data.append('notificacion', not);
        data.append('hecho', 1);
        dispatch(confirmTaskScheduled(token, item.id, data));
    }

    function _saveTaskScheduled() {
        setIsLoading(true);
        const data = new FormData();
        resources.forEach((file, i) => {
            if (file.tipo) {
                data.append(`resultados`, file.archivo, file.nombre + ',' + file.id.toString());
            }
        });
        images.forEach((file, i) => {
            data.append(`imagenes`, file, file.name);
        });
        data.append('id_observacion', id_observation);
        data.append('observacion_final', observationEnd);
        data.append('notificacion', 0);
        data.append('hecho', 0);
        dispatch(confirmTaskScheduled(token, item.id, data));
    }

    const [isStore, setIsStore] = useState(false);
    const [typeLog, setTypeLog] = useState('');

    // eslint-disable-next-line
    async function getTaskScheduled(date) {
        const value_date = convertDate(date);
        const params = '?usuario=' + user + '&&fecha=' + value_date;
        const response = await searchTaskScheduledUser(token, params);
        setData(response.data.datos)
    }

    function changeIsStore(value) {
        getStores(user, token, value);
        setIsStore(value);
    }

    async function get_log(id) {
        setIsLoading(true);
        const params = '?id_tarea_horario=' + id;
        const response = await getLogTaskScheduled(token, params);
        if (response.status === 200) {
            setTypeLog(response.data.tipo_log)
        }
        setIsLoading(false);
    }

    async function create_log(type) {
        setIsLoading(true);
        const data = {tipo: type, id_tarea_horario: item.id};
        const response = await createLogTaskScheduled(token, data);
        if (response.status === 201) {
            if (type === 'Iniciar tarea' || type === 'Continuar tarea') {
                setModalConfirm(true);
                setIsConfirm(false);
                setResources(item.resultados);
                if (item.id_observacion) {
                    setIdObservation(item.id_observacion);
                    setObservation(item.observacion);
                } else {
                    setIdObservation(0);
                    setObservation('');
                }
                setObservationEnd(item.observacion_final);
                setImages([]);
                setNotification(0);
                permisos.filter(x => x.codigo === 'send_notification').length > 0 && (
                    document.getElementById("notification_id").checked = false
                )
            }
            if (type === 'Pausar tarea') {
                get_log(item.id);
                toast.success("Se ha pausado la tarea seleccionada", {position: toast.POSITION.TOP_RIGHT});
            }
        }
        setIsLoading(false);
    }

    const _cancel_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._cancel_task_scheduled
    );

    useEffect(() => {
        if (_cancel_task_scheduled.status === 200) {
            setModalDetail(false);
            getTaskScheduled(date[0]);
        } else if (_confirm_task_scheduled.status !== 0) {
            //dispatch(clear_task_scheduled(CANCEL_TASK_SCHEDULED));
            toast.error(_cancel_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_cancel_task_scheduled, dispatch]);


    function cancel_task_scheduled() {
        setIsLoading(true);
        dispatch(cancelTaskScheduled(token, item.id));
    }

    const handleChangeSelect = (e) => {
        setTaskSelect(e);
    };

    const _confirm_skip_task_scheduled = useSelector(
        (state) => state.task_scheduled_module._confirm_skip_task_scheduled
    );

    useEffect(() => {
        if (_confirm_skip_task_scheduled.status === 200) {
            setIdSkipTask(0);
            document.getElementById("id_skip_task").selectedIndex = 0;
            setModalSkip(false);
            setModalDetail(false);
            getTaskScheduled(date[0]);
        } else if (_confirm_skip_task_scheduled.status !== 0) {
            toast.error(_confirm_skip_task_scheduled.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_confirm_skip_task_scheduled, dispatch]);

    function confirm_skip_task() {
        setIsLoading(true);
        if (!id_skip_task) {
            toast.error("Debe seleccionar un motivo", {position: toast.POSITION.TOP_RIGHT});
            //setIsLoading(false);
            return;
        }
        dispatch(confirmSkipTaskScheduled(token, item.id, {'id_skip_task': id_skip_task}));
    }

    return (
        <>
            <div className="app-content content">
                <ToastContainer/>
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 ">
                            <Breadcrumbs
                                title="Tareas"
                                items={[{label: "Lista", link: "/tareas"}]}
                            />
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    {
                                        permisos.filter(x => x.codigo === 'add_taskscheduled').length > 0 && (
                                            <div className="text-right mb-1">
                                                {/*eslint-disable-next-line*/}
                                                <a href="#"
                                                   onClick={() => {
                                                       setIdTaskList(0);
                                                       setIdTask(0);
                                                       setTaskSelect([]);
                                                       setIdStore(0);
                                                       //setFiles([]);
                                                       setObservationInitial('');
                                                       setModalCreate(true);
                                                       /* document.getElementById("id_task_list").selectedIndex = 0;
                                                       document.getElementById("id_task").selectedIndex = 0; */
                                                       document.getElementById("id_store").selectedIndex = 0;
                                                   }} className="btn text-bold-700 btn_4"> + </a>
                                            </div>
                                        )
                                    }
                                    <div className="row mb-3">
                                        <div className="col-12" style={{justifyContent: 'center', display: 'flex'}}>
                                            <Calendar
                                                onChange={(value) => {
                                                    onChangeCalendar(value)
                                                }}
                                                value={date}
                                                tileClassName={(date1) => {
                                                    let day = date1.date.getDate();
                                                    let month = date1.date.getMonth() + 1;
                                                    if (day<10) {
                                                        day = '0' + day;
                                                    }
                                                    if (month<10) {
                                                        month = '0' + month;
                                                    }
                                                    const realDate = date1.date.getFullYear() + '-' + month + '-' + day;
                                                    if (dateCalendar && dateCalendar.find(val=> val===realDate)){
                                                        return 'highlight'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        {data && data.map((item) => (
                                            <div className="col-md-4 col-sm-6 col-12">
                                                <CardItem
                                                    color={'#0D2339'}
                                                    onClick={
                                                        () => {
                                                            setItem(item);
                                                            setModalDetail(true);
                                                            get_log(item.id);
                                                            for (const tl of taskList) {
                                                                console.log(item.tarea.lista.nombre)
                                                                console.log(tl)

                                                                if (tl.nombre === item.tarea.lista.nombre) {
                                                                    setTask(tl.tareas);
                                                                    break;
                                                                }
                                                            }
                                                            getTaskList(tipoTarea, token);
                                                            getTask(user, token)
                                                        }
                                                    }
                                                >
                                                    <h4 style={{color: '#FFC912'}}
                                                        className="text-bold-700">{item.tarea.nombre}</h4>
                                                    <p style={{color: '#FFC912'}}>{item.horario.estado} <span
                                                        style={{fontSize: 18}}
                                                        className="text-bold-500">|</span> {item.horario.tienda}</p>
                                                    <p style={{color: '#FFC912'}}>{item.horario.fecha_agendada}</p>
                                                </CardItem>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-main" show={modalCreate} style={{width: "80%"}}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="row m-1">
                            <div className="col-6 text-left">
                                {/*eslint-disable-next-line*/}
                                <a href="#" onClick={() => {
                                    setModalCreate(false);
                                    setIsStore(false);
                                    getStores(user, token, false);
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/close.png"
                                        }
                                        className="img_4"
                                        alt="Salir"
                                    />
                                </a>
                            </div>
                            <div className="col-6 text-right">
                                {/*eslint-disable-next-line*/}
                                <a href="#" onClick={() => {
                                    save_task_scheduled();
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/save.png"
                                        }
                                        className="img_4"
                                        alt="Guardar"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                <div className="col-6">
                                    <h6 className="texto_16">Nueva tarea</h6>
                                </div>
                                <div className="col-6 text-right">
                                    <h6 className="texto_16">{convertDate(date[0])}</h6>
                                </div>
                            </div>
                            <div className="row"
                                 style={{background: '#F4F4F4', borderRadius: 10, padding: 10}}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tareas</label>
                                        <Select
                                            options={tasks}
                                            isMulti={true}
                                            onChange={handleChangeSelect}
                                            value={task_select}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="form-group">
                                        <label>Lista de tareas</label>
                                        <SelectInput
                                            id="id_task_list"
                                            label="lista_tarea"
                                            name="id_task_list"
                                            value={id_tasklist}
                                            onChange={handleChangeTaskList}
                                            options={taskList ? taskList.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tarea</label>
                                        <SelectInput
                                            label="tarea"
                                            id="id_task"
                                            name="id_task"
                                            value={id_task}
                                            onChange={handleChangeTask}
                                            options={task ? task.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tienda</label>
                                        <SelectInput
                                            label="tienda"
                                            id="id_store"
                                            name="id_store"
                                            value={id_store}
                                            onChange={handleChangeStore}
                                            options={stores ? stores.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input"
                                                    onClick={() => {
                                                        changeIsStore(!isStore);
                                                    }}  checked={isStore}
                                                    style={{width: 15, height: 15, marginTop: -1}}/>
                                            <span className="ml-1">Tarea en reemplazo</span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-12 mt-1">
                                    <div className="form-group">
                                        <TextArea
                                            label="Observación inicial"
                                            name="observationInitial"
                                            id="observationInitial"
                                            onChange={handleChangeObservationInitial}
                                            value={observationInitial}
                                            rows={5}
                                            placeholder="Observación inicial"
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-12 mt-2">
                                    <div className="row">
                                        {(files) &&
                                            files.map((_) => (
                                                <>
                                                    <div className="col-10">
                                                        <div className="d-flex">
                                                            <img
                                                                src={
                                                                    window.location.origin +
                                                                    "/base/app-assets/images/icon/file.png"
                                                                }
                                                                width={25}
                                                                height={25}
                                                                alt="File"
                                                            />
                                                            <span className="ml-2">{_.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 text-right pr-1">
                                                        <a href="#" onClick={() => {
                                                            const index = files.indexOf(_);
                                                            files.splice(index, 1);
                                                            setFiles([...files]);
                                                        }}>
                                                            <img
                                                                src={
                                                                    window.location.origin +
                                                                    "/base/app-assets/images/icon/delete.png"
                                                                }
                                                                width={25}
                                                                height={25}
                                                                alt="Eliminar"
                                                            />
                                                        </a>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="text-right mt-1">
                                <a href="#" className="btn text-bold-700" style={{
                                    background: '#FFC912',
                                    fontSize: 14,
                                    color: '#000000',
                                    borderRadius: 10,
                                    padding: 10,
                                    position: 'absolute'
                                }}>
                                    Cargar archivo
                                </a>
                                <input type="file" onChange={handleFileChange} multiple
                                       style={{opacity: 0, width: 120, height: 45}}/>
                            </div> */}
                        </div>
                    </Modal>
                    <Modal className="modal-main" show={modalDetail} style={{width: "80%"}}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="row m-1">
                            <div className="col-12 text-right">
                                {/*eslint-disable-next-line*/}
                                <a href="#" onClick={() => {
                                    setModalDetail(false)
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/close.png"
                                        }
                                        width={30}
                                        alt="Salir"
                                    />
                                </a>
                                {(item && item.horario) &&
                                    <h6 className="mt-1">{item.horario.fecha_agendada}</h6>
                                }
                            </div>
                        </div>
                        <div className="card-body">
                            {(item && item.tarea && item.horario) &&
                                <>
                                    <div style={{background: '#F4F4F4', borderRadius: 10, padding: 10}}>
                                        <div className="mb-1">
                                            <span style={{fontSize: 16}}>Lista de tarea</span><br/>
                                            <strong>{item.tarea.lista.nombre}</strong>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{fontSize: 16}}>Tarea</span><br/>
                                            <strong>{item.tarea.nombre}</strong>
                                        </div>
                                        <div className="mb-1">
                                            <span style={{fontSize: 16}}>Tienda</span><br/>
                                            <strong>{item.horario.tienda}</strong>
                                        </div>
                                    </div>
                                    {(!item.hecho && item.horario.estado === 'ACTIVO' && taskList && stores) &&
                                        <div className="text-center mt-2">
                                            {!typeLog &&
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href="#" onClick={() => {
                                                        create_log('Iniciar tarea');
                                                    }} className="btn text-bold-700"
                                                    style={{background: '#FFC912', borderRadius: 10, padding: 10, color: '#000000', width: '70%'}}>
                                                        Iniciar
                                                    </a>
                                                </>
                                            }
                                            {(typeLog) &&
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href="#" onClick={() => {
                                                        create_log('Continuar tarea');
                                                    }} className="btn text-bold-700 mt-1"
                                                    style={{background: '#FFC912', borderRadius: 10, padding: 10, color: '#000000', width: '70%'}}>
                                                        Continuar
                                                    </a>
                                                </>
                                            }
                                            {(typeLog === 'Iniciar tarea' || typeLog === 'Continuar tarea') &&
                                                <>
                                                    {/*eslint-disable-next-line*/}
                                                    <a href="#" onClick={() => {
                                                        create_log('Pausar tarea');
                                                    }} className="btn text-bold-700 mt-1"
                                                    style={{background: '#FFC912', borderRadius: 10, padding: 10, color: '#000000', width: '70%'}}>
                                                        Pausar
                                                    </a>
                                                </>
                                            }
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                setIdTaskList(item.tarea.lista.id);
                                                setIdTask(item.tarea.id);
                                                setIdStore(item.horario.id_tienda);
                                                setObservationInitial(item.observacion_inicial);
                                                //setFiles([]);
                                                setFilesUpdate(item.archivos);
                                                setModalEdit(true);
                                                let cont_task_list = 1;
                                                let cont_task = 1;
                                                for (const tl of taskList) {
                                                    if (tl.nombre === item.tarea.lista.nombre) {
                                                        for (const t of tl.tareas) {
                                                            if (t.nombre === item.tarea.nombre) {
                                                                break
                                                            }
                                                            cont_task += 1;
                                                        }
                                                        break
                                                    }
                                                    cont_task_list += 1;
                                                }
                                                document.getElementById("id_task_list_edit").selectedIndex = cont_task_list;
                                                document.getElementById("id_task_edit").selectedIndex = cont_task;
                                                let cont_store = 1
                                                for (const i of stores) {
                                                    if (i.nombre === item.horario.tienda) {
                                                        break
                                                    }
                                                    cont_store += 1;
                                                }
                                                document.getElementById("id_store_edit").selectedIndex = cont_store;
                                            }} className="btn text-bold-700 mt-1"
                                               style={{background: '#FFC912', borderRadius: 10, padding: 10, color: '#000000', width: '70%'}}>
                                                Editar
                                                {/*<img*/}
                                                {/*    src={*/}
                                                {/*        window.location.origin +*/}
                                                {/*        "/base/app-assets/images/icon/editar.png"*/}
                                                {/*    }*/}
                                                {/*    width={40}*/}
                                                {/*    alt="Editar"*/}
                                                {/*/>*/}
                                            </a>
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                setModalSkip(true);
                                            }} className="btn text-bold-700 mt-1"
                                               style={{background: '#FFC912', borderRadius: 10, padding: 10, color: '#000000', width: '70%'}}>
                                                Omitir
                                            </a>
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                cancel_task_scheduled();
                                            }} className="btn text-bold-700 mt-1"
                                               style={{background: '#FF5B5C', borderRadius: 10, padding: 10, color: '#FFF', width: '70%'}}>
                                                Anular
                                            </a>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </Modal>
                    <Modal className="modal-main" show={modalEdit} style={{width: "80%"}}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="row m-1">
                            <div className="col-6 text-left">
                                {/*eslint-disable-next-line*/}
                                <a href="#" onClick={() => {
                                    setModalEdit(false)
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/close.png"
                                        }
                                        className="img_4"
                                        alt="Salir"
                                    />
                                </a>
                            </div>
                            <div className="col-6 text-right">
                                {/*eslint-disable-next-line*/}
                                <a href="#" onClick={() => {
                                    edit_task_scheduled();
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/save.png"
                                        }
                                        className="img_4"
                                        alt="Guardar"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-1">
                                <div className="col-6">
                                    <h6 className="texto_16">Editar tarea</h6>
                                </div>
                                <div className="col-6 text-right">
                                    {(item && item.horario && item.reprogramado) ?
                                        <h6 className="texto_16">{item.horario.fecha_agendada}</h6>
                                        :
                                        (item && item.horario) &&
                                        <>
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                setModalEditDate(true);
                                                setMotivo('');
                                            }}>
                                                <h6 className="texto_16" style={{
                                                    background: '#FFC912',
                                                    padding: 5,
                                                    color: '#0D2339'
                                                }}>{item.horario.fecha_agendada}</h6>
                                            </a>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="row" style={{background: '#F4F4F4', borderRadius: 10, padding: 10}}>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Lista de tareas</label>
                                        <SelectInput
                                            id="id_task_list_edit"
                                            label="lista_tarea"
                                            name="id_task_list"
                                            value={id_tasklist}
                                            onChange={handleChangeTaskList}
                                            options={taskList ? taskList.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tarea</label>
                                        <SelectInput
                                            label="tarea"
                                            id="id_task_edit"
                                            name="id_task"
                                            value={id_task}
                                            onChange={handleChangeTask}
                                            options={task ? task.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tienda</label>
                                        <SelectInput
                                            label="tienda"
                                            id="id_store_edit"
                                            name="id_store"
                                            value={id_store}
                                            onChange={handleChangeStore}
                                            select={id_store}
                                            options={stores ? stores.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TextArea
                                            label="Observación inicial"
                                            name="observationInitial"
                                            id="observationInitial"
                                            onChange={handleChangeObservationInitial}
                                            value={observationInitial}
                                            rows={5}
                                            placeholder="Observación inicial"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="row">
                                        {(filesUpdate) &&
                                            filesUpdate.map((_) => (
                                                <div className="col-12" style={{marginBottom: 2}}>
                                                    <div className="d-flex">
                                                        <img
                                                            src={
                                                                window.location.origin +
                                                                "/base/app-assets/images/icon/file.png"
                                                            }
                                                            width={25}
                                                            height={25}
                                                            alt="File"
                                                        />
                                                        <span className="ml-2">{_.name}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {/* {(files) &&
                                            files.map((_) => (
                                                <>
                                                    <div className="col-10">
                                                        <div className="d-flex">
                                                            <img
                                                                src={
                                                                    window.location.origin +
                                                                    "/base/app-assets/images/icon/file.png"
                                                                }
                                                                width={25}
                                                                height={25}
                                                                alt="File"
                                                            />
                                                            <span className="ml-2">{_.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 text-right pr-1">
                                                        <a href="#" onClick={() => {
                                                            const index = files.indexOf(_);
                                                            files.splice(index, 1);
                                                            setFiles([...files]);
                                                        }}>
                                                            <img
                                                                src={
                                                                    window.location.origin +
                                                                    "/base/app-assets/images/icon/delete.png"
                                                                }
                                                                width={25}
                                                                height={25}
                                                                alt="Eliminar"
                                                            />
                                                        </a>
                                                    </div>
                                                </>
                                            ))
                                        } */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-right mt-1">
                                <a href="#" className="btn text-bold-700" style={{
                                    background: '#FFC912',
                                    fontSize: 14,
                                    color: '#000000',
                                    borderRadius: 10,
                                    padding: 10,
                                    position: 'absolute'
                                }}>
                                    Cargar archivo
                                </a>
                                <input type="file" onChange={handleFileChange} multiple
                                       style={{opacity: 0, width: 120, height: 45}}/>
                            </div> */}
                        </div>
                    </Modal>
                    <Modal className="modal-main" show={modalEditDate} style={{width: "80%"}}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="card-body">
                            <div className="text-center">
                                <img
                                    src={
                                        window.location.origin +
                                        "/base/app-assets/images/icon/edit_calendar.png"
                                    }
                                    width={80}
                                    alt="Confirmar"
                                />
                            </div>
                            <div className="text-center mt-1">
                                <h5>Reprogramar tarea a la fecha</h5>
                                <Input
                                    type="date"
                                    name="date_re"
                                    id="date_re"
                                    label="Fecha"
                                    onChange={handleChangeDate}
                                    placeholder=""
                                    value={date_re}
                                />
                            </div>
                            <div className="form-group">
                                <TextArea
                                    label="Motivo"
                                    name="motivo"
                                    id="motivo"
                                    onChange={handleChangeReason}
                                    value={motivo}
                                    rows={5}
                                    placeholder="motivo"
                                />
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-calendar1 mr-1" type="button" onClick={() => {
                                updateDate()
                            }}>OK
                            </button>
                            <button className="btn btn-danger" type="button" onClick={() => {
                                setModalEditDate(false)
                            }}>Cancelar
                            </button>
                        </div>
                    </Modal>
                    <Modal className="modal-main" show={modalConfirm} style={{width: "80%"}}>
                        {isLoading && <LoadingSpinner/>}
                        {/* <div className="row m-1">
                            <div className="col-6 text-left">
                                <a href="#" onClick={() => {
                                    setModalConfirm(false)
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/close.png"
                                        }
                                        width={30}
                                        alt="Salir"
                                    />
                                </a>
                            </div>
                            <div className="col-6 text-right">
                                <a href="#" onClick={() => {
                                    _confirmTaskScheduled()
                                }}>
                                    <img
                                        src={
                                            window.location.origin +
                                            "/base/app-assets/images/logo/save.png"
                                        }
                                        width={30}
                                        alt="Guardar"
                                    />
                                </a>
                            </div>
                        </div> */}
                        <div className="card-body m-1">
                            {(item && item.tarea && item.horario) &&
                                <div className="row">
                                    <div className="col-6 d-flex">
                                        {/*eslint-disable-next-line*/}
                                        <a href="#" onClick={() => {
                                            get_log(item.id);
                                            setModalConfirm(false);
                                            setNotification(false);
                                        }}>
                                            <ReactCancel className='img_4'/>
                                        </a>
                                        <h5 className="ml-1 texto_16">{item.tarea.nombre}</h5>
                                    </div>
                                    <div className="col-6 text-right">
                                        <h6 className="texto_16">{item.horario.fecha_agendada}</h6>
                                    </div>
                                    <div className="col-12 p-1 mt-1" style={{background: '#F4F4F4', borderRadius: 10}}>
                                        <h6>{item.horario.codigo_tienda} {item.horario.tienda}</h6>
                                        <h6>{item.observacion_inicial}</h6>
                                    </div>
                                    <div className="col-12 p-1 mt-1"
                                         style={{background: '#F4F4F4', borderRadius: 10}}>
                                        <h6>Recursos y resultados de la tarea</h6>
                                        {(resources) &&
                                            resources.map((_) => (
                                                <>
                                                    <div style={{marginTop: 5}} className="d-flex">
                                                        <div style={{width: '90%'}}>
                                                            <strong className="ml-1">{_.nombre_template}</strong>
                                                        </div>
                                                        <div>
                                                            {/*eslint-disable-next-line*/}
                                                            <a href={URLAPI + _.archivo_template} target="_blank" className="ml-1">
                                                                <img
                                                                    src={
                                                                        window.location.origin +
                                                                        "/base/app-assets/images/icon/download.png"
                                                                    }
                                                                    width={25}
                                                                    height={25}
                                                                    alt="File"
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {!_.archivo ?
                                                        <div className="ml-1 d-flex">
                                                            <div style={{width: '65%'}}>
                                                                <span>Pendiente</span>
                                                            </div>
                                                            <div className="text-center">
                                                                {/*eslint-disable-next-line*/}
                                                                <a style={{
                                                                    position: 'absolute', cursor: 'pointer'
                                                                }}>Cargar resultado
                                                                </a>
                                                                <input type="file" onChange={(e) => handleFileChangeResource(e, _.id)}
                                                                    style={{opacity: 0, width: 160, height: 40, cursor: 'pointer'}}/>
                                                            </div>
                                                        </div>
                                                    : 
                                                        <div className="ml-1 d-flex">
                                                            <div style={{width: '60%'}}>
                                                                <span>Resultado cargado</span><br/>
                                                                <span>{_.nombre}</span>
                                                            </div>
                                                            {/*eslint-disable-next-line*/}
                                                            <div style={{width: '20%'}}>
                                                                {/*eslint-disable-next-line*/}
                                                                <a href={URLAPI + _.archivo} target="_blank" className="ml-1">Ver</a>
                                                            </div>
                                                            <div style={{width: '15%'}}>
                                                                {/*eslint-disable-next-line*/}
                                                                <a style={{
                                                                    position: 'absolute', cursor: 'pointer'
                                                                }}>Actualizar
                                                                </a>
                                                                <input type="file" onChange={(e) => handleFileChangeResource(e, _.id)}
                                                                    style={{opacity: 0, width: 160, height: 40, cursor: 'pointer'}}/>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                    {/* <div className="col-12 p-1 mt-1"
                                         style={{background: '#F4F4F4', borderRadius: 10}}>
                                        <h6>Resultados</h6>
                                        <div className="row">
                                            {(resources) &&
                                                resources.map((_) => (
                                                    <>
                                                        <div className="col-10" style={{marginTop: 5}}>
                                                            <div className="d-flex">
                                                                <img
                                                                    src={
                                                                        window.location.origin +
                                                                        "/base/app-assets/images/icon/file.png"
                                                                    }
                                                                    width={25}
                                                                    height={25}
                                                                    alt="File"
                                                                />
                                                                <span className="ml-2">{_.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 text-right pr-1">
                                                            <a href="#" onClick={() => {
                                                                const index = resources.indexOf(_);
                                                                resources.splice(index, 1);
                                                                setResources([...resources]);
                                                            }}>
                                                                <img
                                                                    src={
                                                                        window.location.origin +
                                                                        "/base/app-assets/images/icon/delete.png"
                                                                    }
                                                                    width={25}
                                                                    height={25}
                                                                    alt="Eliminar"
                                                                />
                                                            </a>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                        <div className="text-center mt-1">
                                            <button className="btn text-bold-700" style={{
                                                position: 'absolute',
                                                background: '#FFC912',
                                                fontSize: 14,
                                                color: '#000000'
                                            }}>Cargar archivo
                                            </button>
                                            <input type="file" onChange={handleFileChangeResource} multiple
                                                   style={{opacity: 0, width: 140, height: 40}}/>
                                        </div>
                                    </div> */}
                                    <div className="col-12 p-1 mt-1"
                                         style={{background: '#F4F4F4', borderRadius: 10}}>
                                        <h6>Fotografías de evidencia</h6>
                                        <div className="row">
                                            {(item.imagenes.length > 0) &&
                                                item.imagenes.map((_) => (
                                                    <div className="col-4 text-center">
                                                        <img
                                                            src={URLAPI + _.imagen}
                                                            width={90}
                                                            height={82}
                                                            alt={_.nombre}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            {(images) &&
                                                images.map((_, index) => (
                                                    <div className="col-4 text-center d-flex">
                                                        <img
                                                            src={URL.createObjectURL(_)}
                                                            width={90}
                                                            height={82}
                                                            alt={_.name}
                                                        />
                                                        {/*eslint-disable-next-line*/}
                                                        <a href="#" onClick={() => {
                                                                const index = images.indexOf(_);
                                                                images.splice(index, 1);
                                                                setImages([...images]);
                                                            }}>
                                                            <img
                                                                src={
                                                                    window.location.origin +
                                                                    "/base/app-assets/images/icon/close.png"
                                                                }
                                                                width={25}
                                                                height={25}
                                                                alt="Eliminar"
                                                            />
                                                        </a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {((images.length + item.imagenes.length) < 3) &&
                                            <div className="text-center mt-1">
                                                <button className="btn text-bold-700" style={{
                                                    position: 'absolute',
                                                    background: '#FFC912',
                                                    fontSize: 14,
                                                    color: '#000000',
                                                    width: '70%'
                                                }}>Cargar imagen
                                                </button>
                                                <input accept="image/*" type="file" onChange={handleFileChangeImage}
                                                       multiple style={{opacity: 0, width: '70%', height: 40}}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Observación final</label>
                                            <SelectInput
                                                id="id_observation"
                                                label="Observación final"
                                                name="id_observation"
                                                value={id_observation}
                                                select={observation}
                                                onChange={(e) => {setIdObservation(e.target.value)}}
                                                options={observations ? observations.map((item) => ({
                                                    name: item.nombre,
                                                    id: item.id,
                                                })) : []}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-1">
                                        <div className="form-group">
                                            <TextArea
                                                label="Observación final"
                                                name="observationEnd"
                                                id="observationEnd"
                                                onChange={handleChangeObservationEnd}
                                                value={observationEnd}
                                                rows={5}
                                                placeholder="Observación final"
                                            />
                                        </div>
                                        {
                                            permisos.filter(x => x.codigo === 'send_notification').length > 0 && (
                                                <div className="form-check">
                                                    <label className="form-check-label mt-1">
                                                        <input type="checkbox" className="form-check-input"
                                                               onClick={() => {
                                                                   setNotification(!notification);
                                                               }} id="notification_id" checked={notification}
                                                               style={{width: 15, height: 15, marginTop: -1}}/>
                                                        <span className="ml-1">Notificar a administrador</span>
                                                    </label>
                                                </div>
                                            )
                                        }
                                        <div className="mt-2 text-center">
                                            <button className="btn" style={{background: '#FFC912', fontSize: 14, color: '#000000', width: '70%'}} 
                                            type="button" onClick={() => {_saveTaskScheduled()}}>Guardar cambios</button>
                                            <button className="btn mt-1" style={{background: '#FFC912', fontSize: 14, color: '#000000', width: '70%'}} 
                                            type="button" onClick={() => {setIsConfirm(true); _confirmTaskScheduled();}}>Finalizar tarea</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                    <Modal className="modal-main" show={modalSkip} style={{width: "70%"}}>
                        <div className="m-1 text-center">
                            <img 
                                src={window.location.origin + "/base/app-assets/images/icon/help.png"}
                                alt="img"
                                className='img_3'
                            />
                            <br/><br/>
                            <p className='texto_24 text-bold-400'>¿Desea omitir la tarea?</p>
                            <br/><br/>
                            <div className="row">
                                <div className="col-12">
                                    <div className=" text-left form-group">
                                        <label>Motivo</label>
                                        <SelectInput
                                            id="id_skip_task"
                                            label="Detalle de omitir tarea"
                                            name="id_skip_task"
                                            value={id_skip_task}
                                            onChange={(e) => {setIdSkipTask(e.target.value)}}
                                            options={skips ? skips.map((item) => ({
                                                name: item.nombre,
                                                id: item.id,
                                            })) : []}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                            {/*eslint-disable-next-line*/}
                            <a href="#" onClick={() => {
                                confirm_skip_task();
                            }} className="btn text-bold-400 mb-1 btn_1">
                                SI
                            </a>
                            {/*eslint-disable-next-line*/}
                            <a href="#" onClick={() => {
                                setIdSkipTask(0);
                                document.getElementById("id_skip_task").selectedIndex = 0;
                                setModalSkip(false);
                            }} className="btn text-bold-400 btn_3">
                                NO
                            </a>
                        </div>
                    </Modal>
                </div>
            </div>
            <div className="app-content content">
                <Footer/>
            </div>
        </>
    )
}

export default TaskScheduledUser;
